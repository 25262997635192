<template>
    <main>
         <Hero/>
         <About/>
         <Features/>
         <KeyFeatures />
         <UseCase/>
         <MoreFeatures/>
         <RequestTrialSection/>
    </main>
</template>

<script>
import About from './About.vue';
import Hero from './Hero.vue';
import Features from './Features.vue';
import KeyFeatures from './KeyFeatures.vue';
import UseCase from './UseCase.vue';
import MoreFeatures from './MoreFeatures.vue';
import RequestTrialSection from './RequestTrialSection.vue';

export default {
    components: { 
        Hero, 
        About,
        Features,
        KeyFeatures,
        UseCase,
        MoreFeatures,
        RequestTrialSection 
    }
}
</script>
