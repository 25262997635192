<template>
  <div class="d-flex justify-content-between align-items-center p-3 bg-light">
    <Logo />

    <nav :class="[`navigation ${isOpenMenu ? 'active' : ''}`]">
      <ul class="nav gap-4 align-items-center">
        <li @click="closeMenu" class="nav-item">
          <a class="nav-link" href="#home">Home</a>
        </li>
        <li @click="closeMenu" class="nav-item">
          <a class="nav-link" href="#about">About</a>
        </li>
        <li  @click="closeMenu" class="nav-item">
          <a class="nav-link" href="#features">Features</a>
        </li>
        <li @click="closeMenu" class="nav-item">
          <a class="nav-link" href="#usecase">Use case</a>
        </li>
        <li class="nav-item">
          <a href="https://portal.ottochat.ai" target="_blank" class="btn btn-primary btn-round no-border btn-shadow">
            Login
          </a>
        </li>
      </ul>
    </nav>

    <button
      @click="handleOpenMenu"
      class="btn btn-light btn-shadow btn-sm btn-round menu-navigation"
    >
      <i
        v-if="!isOpenMenu"
        style="font-size: 20px"
        class="fa-solid fa-bars"
      ></i>
      <i
        v-if="isOpenMenu"
        style="font-size: 20px"
        class="fa-solid fa-xmark"
      ></i>
    </button>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
export default {
  components: {
    Logo,
  },
  data() {
    return {
      isOpenMenu: false,
    };
  },
  methods: {
    handleOpenMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },
    closeMenu() {
      this.isOpenMenu = false;
    }
  },
};
</script>
