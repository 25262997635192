<template>
  <div class="section section-description bg-white" id="request_trial">
    <div class="section-wrapper padding-top padding-bottom">
      <div class="section-title text-center">
        <h2 class="display-4 display-title text-gradient adaptive-title-fz">Request Trial</h2>
        <p class="anim fadein-top td-3 text-secondary">
          Ready to transform your customer communication? Sign up for a free
          trial and experience the future with Ottochat.ai.
        </p>
      </div>

      <div class="section-content anim text-center">
        <div
          class="row align-items-center justify-content-center sr-zoomin-td4"
        >
          <div class="col-12 col-md-8 col-lg-6">
            <form @submit.prevent="submit">
              <div class="form-input">
                <div class="form-group">
                  <span class="d-flex mb-2 text-gradient">Email</span>
                  <input
                    v-model="email"
                    class="form-control form-control-line form-control-white"
                    placeholder="your@email.address"
                  />
                  <span v-if="errors.email" class="d-flex text-danger">{{errors.email}}</span>
                </div>

                <div class="form-group">
                  <span class="d-flex mb-2 text-gradient">Company</span>
                  <input
                    v-model="company"
                    class="form-control form-control-line form-control-white"
                    type="text"
                    placeholder="Enter company"
                  />
                  <span v-if="errors.company" class="d-flex text-danger">{{errors.company}}</span>
                </div>

                <div class="form-group form-success-gone">
                  <span class="d-flex mb-2 text-gradient">Message</span>
                  <textarea
                    v-model="message"
                    style="resize: none"
                    cols="3"
                    class="form-control form-control-line form-control-white"
                    placeholder="Message..."
                  />
                </div>

                <div class="form-group form-btn-right">
                  <button
                    v-if="!isSend"
                    :disabled="isLoading"
                    class="btn btn-primary btn-round btn-shadow"
                    type="submit"
                  >
                    <span v-if="!isLoading" class="icon">
                      Send <i class="fa-solid fa-paper-plane"></i>
                    </span>
                    <div v-else class="spinner-border text-white" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>

                    <div v-if="isSend" class="alert alert-success mt-3" role="alert">
                      The request has been sent, wait for a response
                   </div>

                  
                   <div v-if="isError" class="alert alert-danger mt-3" role="alert">
                       Something went wrong, try again
                   </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        isSend: false,
        isError: false,
        email: "",
        company: "",
        message: "",


        errors: {}
      }
    },
    methods: {
      isInvalidFormData() {
        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let objectErros = {};

        if(!this.email) {
          objectErros.email = 'Email is required';
        }
        if(this.email && !regexEmail.test(this.email)) {
          objectErros.email = 'Email is invalid';
        }

        if(!this.company) {
          objectErros.company = 'Company is required';
        }

        if(Object.keys(objectErros).length > 0) {
          this.errors = {...objectErros};
          setTimeout(() => {
              this.errors = {};
              this.isLoading = false;
          }, 1000);
          return true;
        }

        return false;

      },
     async submit() {
       // console.log(`email: ${this.email}, company: ${this.company}, message: ${this.message}`);
       this.isError = false;
       this.isSend = false;
       this.isLoading = true;

        if(this.isInvalidFormData()) {
          return;
        }

        try {
         await fetch(
           "https://us-central1-car-appointment-fsqahl.cloudfunctions.net/appticketFwd",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorize: "f3CIZUdYwzlj64vydOaci7NTPy0wZf4V",
          },
          body: JSON.stringify({
            business_group: this.company,
            location: "",
            name: "",
            type: "trial_request",
            contact_email: this.email,
            message: this.message,
          }),
        }
      );
          this.isSend = true;
        }
        catch(e) {
          this.isError = true;
        }

        finally {
          this.isLoading = false;
          this.email = '';
          this.company = '';
          this.message = '';
        }

      }
    }
  }
</script>
