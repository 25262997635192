<template>
  <div id="app">
    <Header />
    <PageCover />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Header from "./components/header/Wrapper.vue";
import Main from "./components/main/Wrapper.vue";
import PageCover from "./components/PageCover.vue";
import Footer from "./components/footer/Wrapper.vue";

export default {
  name: "App",
  components: { Header, PageCover, Main, Footer },
};
</script>
