<template>
    <header class="header">
        <Navbar/>
    </header>
</template>

<script>
import Navbar from './Navigation/Navbar.vue';
    export default {
        components: {Navbar}
    }
</script>

