<template>
    <div class="section section-footer bg-white bg-bright">
     
      <div class="section-wrapper">
        <div class="section-content">
          <footer class="footer footer-alpha text-center">
            <nav class="footer-nav">
              <div class="row">
                <div class="col-12">
                 
                  <div class="d-flex justify-content-center mb-3">
                    <Logo/>
                  </div>
                </div>
                <div class="col-12 nav-container">
                  <div  class="d-flex justify-content-center flex-column flex-lg-row">
                    <div class="nav-item active">
                      <a class="nav-link text-gradient" target="_blank" href="/privacy-policy">Privacy Policy</a>
                    </div>
                    <div class="nav-item">
                      <a target="_blank" href="/terms-of-service" class="nav-link text-gradient">Terms of Service</a>
                    </div>
                  </div>
                </div>

              </div>
            </nav>
          </footer>
        </div>
      </div>
    </div>
</template>

<script>
import Logo from '../header/Navigation/Logo.vue';

export default {
    components: {Logo}
}
</script>