<template>
  <div
    class="section section-twoside fullheight bg-white bg-bright"
    id="usecase"
  >
    <div class="section-wrapper twoside padding-bottom padding-top">
      <div class="section-title text-center">
        <h2 class="display-4 display-title adaptive-title-fz">Use Case</h2>
      </div>

      <div
        class=""
      >
        <div class="d-flex flex-column flex-lg-row mb-4 justify-content-center align-items-center">
          <span
            :class="['tab', active_tab === tab.value ? 'active' : '']"
            @click="active_tab = tab.value"
            v-for="tab in tabs"
            :key="tab.value"
            style="cursor: pointer;"
          >
            {{ tab.text }}
          </span>
        </div>

        <div class="item row">
            <div class="col-12 col-md-6 col-lg-5 order-md-1">
              <div class="section-content anim">
                <div class="images text-center">
                  <div class="img-frame-normal full img-black">
                    <div class="d-flex justify-content-center">
                      <img
                        class="img"
                        alt="Image"
                        v-if="data[active_tab] && data[active_tab]['imgSrc']"
                        :src="data[active_tab]['imgSrc']"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-7 order-md-2 center-vh">
              <div class="section-content">
                <div class="title-desc">
                  <h2
                    class="display-5 display-title text-black"
                  >
                    {{ data[active_tab].title }}
                  </h2>
                  <p class="">
                    {{ data[active_tab].description }}
                  </p>
                  <div class="row td-4">
                    <div class="col-12 mb-3">
                      <div class="">
                        <div class="d-flex justify-content-start">
                          <h4 class="border-gradient text-gradient">
                            Feautures
                          </h4>
                        </div>
                        <ul v-if="data[active_tab]" class="d-flex flex-column m-0 p-0">
                          <li
                          :key="featureInd"
                          v-for="(feature, featureInd) in data[active_tab]['feautures']"
                            class="d-flex justify-content-start align-items-center"
                          >
                            <i
                              class="fa-solid fa-circle-check mr-2 text-gradient"
                            ></i>
                            {{ feature }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active_tab: "marketing",
      tabs: [
        {
          text: "Marketing",
          value: "marketing",
        },
        {
          text: "Service",
          value: "service",
        },
        {
          text: "Sales",
          value: "sales",
        },
        {
          text: "Product Inquiries",
          value: "product_inquiries",
        },
        {
          text: "Personalization",
          value: "personalization",
        },
      ],

      data: {
        marketing: {
          title: "Marketing Campaigns",
          description:
            "Engage potential customers with targeted, interactive chat campaigns",
          feautures: ['Email/sms broadcasts', 'Smart lists'],
          imgSrc: '/assets/mkt1.png'  
        },
        service: {
          title: "Service Appointments",
          description:
            "Simplify booking service appointments with intuitive chat solutions",
            feautures: ['AI powered appointment scheduling', 'Status display'],
            imgSrc: '/assets/service.png'      
        },
        sales: {
          title: "Automotive Sales",
          description: "Enhance your sales process with AI-driven chat support",
          feautures: ['Lead pushing', 'Smart suggestions']  ,
          imgSrc: '/assets/sales.png'  
        },
        product_inquiries: {
          title: "Product Inquiries",
          description:
            "Answer product-related questions in real-time, boosting customer satisfaction.",
            feautures: ['AI powered replies', 'Inventory search'],
            imgSrc: '/assets/prinq.png'    
        },
        personalization: {
          title: "Customer Feedback Collection",
          description: "Collect and analyze customer feedback effortlessly",
          feautures: ['Customer recognition', 'Events autoreply setups'] ,
          imgSrc: '/assets/pers.png'   
        },
      },
    };
  },
};
</script>
